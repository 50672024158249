import React from 'react';
import HomeSlider from './HomeSlider';
import CategorySlider from './CategorySlider';
// import ProductListPage from './ProductListPage';
// import VideoContent from './VideoContent';
import { siteConfig } from '../../Config/Config';
import VideoTab from './VideoTab';
import PriceList from './PriceList';
import CustomerReview from '../../Components/CustomerReview';
import FAQ from './FAQ';
import Info from './Info';
import Insights from '../../Components/Insights';
import Banner from './Banner';
import ClientList from './ClientList';
import ProductListSlider from './ProductListSlider';
import BuyInBulkSlider from '../../Components/BuyInBulkSlider';
import { ProductSliderList } from '../../Data/localData';
import HomeTitleTab from './HomeTitleTab';
import FeaturesList from './FeaturesList';
import CategoryList from './CategoryList';
import IntegrateBanner from './IntegrateBanner';
import SupplierBanner from './SupplierBanner';
import ProfitCalculator from '../../Components/ProfitCalculator';

document.title = `Home | ${siteConfig?.name}`;

function Home() {

    return (
        <div>
            <HomeTitleTab />
            <FeaturesList />
            <CategoryList />
            <Info />
            <IntegrateBanner />
            <PriceList />
            <SupplierBanner />
            <ProfitCalculator products={ProductSliderList} />
            <FAQ />
            <Banner />
            <Insights />

            {/* 
             "Prevoius Data Design List"

            <HomeSlider />
            <CategorySlider />
            <ClientList />
            <VideoTab />
            <ProductListSlider />
            <PriceList />
            <CustomerReview />
            <FAQ />
            <Insights />
            <Banner /> */}

            {/* <ProductListPage /> */}
            {/* <VideoContent /> */}
        </div>
    );
}

export default Home;
