import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaLink } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function SupplierBanner() {
    return (
        <div className='py-5 px-3 px-sm-0'>
            <Container className='cs-container-xl custom-supplier-banner py-5 full-border-rad overflow-hidden'>
                <Row>
                    <Col className='col-12 col-md-12 flex-center-align py-4'>
                        <div className='lg-max-w-750px d-grid gap-3 text-center'>
                            <h2 className='m-0 h1 fw-semibold'>Looking for a supplier?</h2>
                            <p className='m-0 px-sm-5 fs-18 fs-sm-14'>Find our curated marketplace to find top print-on-demand suppliers tailored to your business needs.</p>
                            <div className='flex-center-align'>
                                <Link className="temp-btn fw-semibold">Explor Products</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SupplierBanner