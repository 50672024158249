import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'

function CommonBrands({ sliderTitle = "Trusted by well-known brands.", containerType = "cs-container-lg", titleCSS, sideEffect = false }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    const imgList = [
        require("../Assets/Images/Untitled design (19).png"),
        require("../Assets/Images/Untitled design (20).png"),
        require("../Assets/Images/Untitled design (21).png"),
        require("../Assets/Images/Untitled design (22).png"),
    ]
    return (
        <div>
            <Container className={`py-sm-5 pb-5 common-brand ${containerType ?? ""}`}>
                <h5 className={`text-center m-0 fw-semibold pb-3 pb-sm-4 ${titleCSS ?? ""}`}>{sliderTitle}</h5>
                <div className="slider-container overflow-hidden position-relative">
                    {
                        (sideEffect) &&
                        <>
                            <div className='position-absolute start-0 h-100 aspect-1-1' style={{ zIndex: "100", backgroundImage: "linear-gradient(to right, #f5eee9, transparent)" }} />
                            <div className='position-absolute end-0 h-100 aspect-1-1' style={{ zIndex: "100", backgroundImage: "linear-gradient(to right, transparent, #f5eee9)" }} />
                        </>
                    }
                    <Slider {...settings}>
                        {
                            imgList.map((item, index) => (
                                <div key={index}>
                                    <div className='common-brand-img-holder'>
                                        <img src={item} alt="" />
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </Container>
        </div>
    )
}

export default CommonBrands