import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { GoDotFill } from 'react-icons/go';
import { IoDiamondOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { GrAchievement } from "react-icons/gr";
import { FaFileSignature } from 'react-icons/fa';

function FeaturesList() {

    const mistakes = [

        {
            icon: <IoDocumentTextOutline />,
            title: "Proposals",
            description: "Share Tools in minutes with pre-built, customizable templates."
        },
        {
            icon: <IoDiamondOutline />,
            title: "Quotes",
            description: "Find out in real time when they’re opened, viewed and signed."
        },
        {
            icon: <GrAchievement />,
            title: "Contracts",
            description: "Keep your data secure with our legally-binding e-Signature software."
        },
        {
            icon: <FaFileSignature />,
            title: "eSignatures",
            description: "Keep your data secure with our legally-binding e-Signature software."
        }
    ]

    return (
        <div className='custom-features py-5'>
            <Container className='pt-4 py-sm-5 pb-sm-4 cs-container-xl'>
                <div className='flex-center-align pb-4 pb-sm-5' data-aos="fade-up">
                    <div className='cs-container-lg max-w-600px text-center d-grid gap-3 gap-sm-4'>
                        <div className='flex-center-align'>
                            <div className='custom-home-title-light'>
                                <GoDotFill className='fs-12' />
                                <span className='fs-12'>Main features</span>
                            </div>
                        </div>
                        <h2 className='m-0 h1 fw-semibold d-grid d-sm-flex justify-content-sm-center gap-2'>
                            <span className='flex-center-align gap-2'>
                                Smart
                                <span className='custom-diff-title '> features for</span>
                            </span>
                            your business
                        </h2>
                        <p className='m-0 p-color lg-max-w-750px'>We focus on helping you sell products online with no minimum order quantity or inventory management. Sell hasslefree with our print on demand services.</p>
                    </div>
                </div>
                <Row className='gy-4'>
                    <Col className='col-12 col-sm-6 col-xl-4 d-grid' data-aos="fade-up" data-aos-delay="500">
                        <div className='custom-feature-list custom-feature-list-fifth d-grid align-content-start border-rad-md-half p-4 gap-4'>
                            <div className='custom-feature-list-img'>
                                <img src={require('../../Assets/Images/template/Screenshot 2024-09-03 124834.png')} alt="" />
                            </div>
                            <div className='custom-feature-list-cnt d-grid gap-3'>
                                <h4 className='m-0 fw-semibold text-center text-sm-start'>FREE Snap Store and Integration</h4>
                                <p className='m-0 fs-16 fs-sm-16 text-center text-sm-start p-color'>Don’t have a website? Create a FREE snap store and start selling products. No coding required. Just select a theme, add products and promote your store. Integrate your online store if you already have one  to automate order processing and managing.</p>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-sm-6 col-xl-4 d-grid' data-aos="fade-up" data-aos-delay="500">
                        <div className='custom-feature-list custom-feature-list-fifth d-grid align-content-start border-rad-md-half p-4 gap-4'>
                            <div className='custom-feature-list-img'>
                                <img src={require('../../Assets/Images/template/Screenshot_1.png')} alt="" />
                            </div>
                            <div className='d-grid gap-3'>
                                <h4 className='m-0 fw-semibold text-center text-sm-start fs-sm-14'>Wide Product Catalog and Product Customizer</h4>
                                <p className='m-0 fs-16 fs-sm-14 text-center text-sm-start p-color'>Choose from 300+ products to customize and sell online. Use our free inbuilt product customizer to create designs using text and stickers. </p>
                                <Link className='color-temp fw-semibold text-decoration-none d-flex gap-2'>
                                    See all integrations
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-sm-6 col-xl-4 d-grid' data-aos="fade-up" data-aos-delay="500">
                        <div className='custom-feature-list custom-feature-list-fifth d-grid align-content-start border-rad-md-half p-4 gap-4'>
                            <div className='custom-feature-list-img'>
                                <img src={require('../../Assets/Images/template/Screenshot_2.png')} alt="" />
                            </div>
                            <div className='custom-feature-list-cnt d-grid gap-3'>
                                <h4 className='m-0 fw-semibold text-center text-sm-start'>Choose From Suppliers</h4>
                                <p className='m-0 fs-16 fs-sm-16 text-center text-sm-start p-color'>Pick a supplier that matches your product requirement and shipping location. All orders are shipped within 48 hours. Take your brand global with international shipping services.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <Container fluid className='custom-features-mistake py-4 py-sm-5 mt-5'>
                <Container className='cs-container-xl py-4 py-sm-5 d-grid gap-5'>
                    <h2 className='h1 m-0 fw-bold text-center'>Prevent costly mistakes</h2>
                    <Row className='gy-4'>
                        {
                            mistakes?.map((item, index) => (
                                <Col key={index} className='col-12 col-sm-6 col-lg-4 col-xl-3 d-grid'>
                                    <div className={`mistake-list px-4 py-5 d-grid gap-3 align-content-center ${(index == 1 ? "active" : "")}`}>
                                        <div className='flex-center-align fs-45'>
                                            {item?.icon}
                                        </div>
                                        <h5 className='text-center m-0 fw-semibold'>{item?.title}</h5>
                                        <p className='text-center m-0 p-color fs-sm-14'>{item?.description}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Link className='color-temp fw-semibold text-decoration-none flex-center-align gap-2'>
                    Discover all features
                        <i className="bi bi-arrow-right"></i>
                    </Link>
                </Container>
            </Container> */}
        </div>
    )
}

export default FeaturesList