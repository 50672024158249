import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom'

function FAQ() {

    const listData = [
        {
            question: 'What is print on demand?',
            answer: 'Print on demand is an eCommerce business model that allows you to sell products online without having to stock them. You partner with a POD partner who produces a product when there is a demand.'
        },
        {
            question: 'How does print on demand work with Printfuse?',
            answer: "Print on demand is simple with Printfuse: Step 1: Sign up at Printfuse Step 2: Set up an online store or create a Free snap store Step 3: Create design and product mockups and list them on your store Step 4: Set your profit margins and promote your business online Step 5: When customers complete a purchase, we ship your order."
        },
        {
            question: 'What is a Printfuse snap store?',
            answer: "Printfuse Snap Store is a free website builder that allows you to create a website without any coding knowledge. Start selling print-on-demand products with ease. Our user-friendly interface and seamless integration with Printfuse's services make it simple to manage your e-commerce storefront. Designed for entrepreneurs and businesses, Snap Store helps you efficiently launch your online store and start generating sales quickly."
        },
        {
            question: "Is Printfuse snap store free?",
            answer: "Yes, creating a snap store is free at Printfuse. Click here to create a snap store."
        },
        {
            question: "Is print on demand worth it?",
            answer: "At Printfuse, we recognize that print-on-demand services are the future of custom products. With the increasing demand for personalized items, our platform offers easy customization of white-label products and rapid, high-quality digital printing. Partnering with Printfuse simplifies the process like never before. Give us a try and experience our superior product quality—we're sure you'll be delighted with the outcome."
        },
        {
            question: "Can I do dropshipping with Printfuse?",
            answer: "Yes, you can dropship products online using Printfuse."
        },
        {
            question: "Is Printfuse free to use?",
            answer: "Yes, Printfuse is completely free to use. Printfuse also offers premium plans that offers multiple other benefits."
        },
        {
            question: "What is the fulfillment time for an order?",
            answer: "All orders are dispatched within 48 hours. You can also select suppliers that offers 24 hours dispatch from our suppliers list."
        },
        {
            question: "How to check the product pricing?",
            answer: "To find product pricing on Printfuse, simply visit our website and explore our product catalog. Each product page displays the pricing details. For any additional questions or concerns, feel free to contact our customer support team for assistance."
        },
        {
            question: "Can I sell on Amazon, Flipkart and other marketplaces using Printfuse?",
            answer: "Printfuse’s print-on-demand and dropshipping services can fulfill Amazon orders through self-shipping services. However, since Flipkart does not currently support self-shipping, we are unable to fulfill orders on their platform at this time."
        }
    ];

    return (
        <div className='py-0 py-md-5 custom-faq-tab'>
            <Container className='pt-0 pb-5 py-sm-5 cs-container-xl'>
                <Row>
                    <Col className='col-12 col-lg-6 d-grid align-content-start'>
                        <div className='custom-sticky-title'>
                            <div className='d-flex pb-4 pb-sm-5'>
                                <div className='lg-max-w-750px max-w-600px d-grid gap-3 gap-sm-4'>
                                    <div className='d-flex justify-content-center justify-content-lg-start'>
                                        <div className='custom-home-title-light'>
                                            <GoDotFill className='fs-12' />
                                            <span className='fs-12'>FAQs</span>
                                        </div>
                                    </div>
                                    <h2 className='fw-semibold m-0 text-center text-lg-start'>Frequenlty Asked Questions</h2>
                                    <p className='m-0 p-color text-center text-lg-start'>At Printfuse, we specialize in quality printing solutions and offer Snap Store, an easy way to create your own website.</p>
                                    <div className='d-flex justify-content-center justify-content-lg-start'>
                                        <Link className='custom-faq-btn d-flex fw-medium gap-2'>Still have a question? <i className="bi bi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-6 d-grid custom-faq-list'>
                        <Accordion alwaysOpen>
                            {
                                listData.map((item, index) => {
                                    return (
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>{item?.question}</Accordion.Header>
                                            <Accordion.Body>
                                                {item?.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FAQ