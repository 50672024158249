import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { headerContent } from '../Data/localData'
import { FaUsps } from "react-icons/fa";
import { FaDhl } from "react-icons/fa";
import { FaFedex } from "react-icons/fa";
import { frontEnd_API, header } from '../Config/Config';
import axios from 'axios';

function Footer() {

  const [webSocialLinks, setWebSocialLinks] = useState([]);

  useEffect(() => {
    axios.get(frontEnd_API.getSocial, header)
      .then((res) => {
        setWebSocialLinks(res?.data?.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
  }, [])

  return (
    <div className='custom-footer'>
      <Container className='cs-container-xl px-0 px-sm-2'>
        <hr className='m-0' />
      </Container>
      <Container className='py-5 pt-sm-5 cs-container-xl'>
        <Row className='gap-3 gap-sm-4'>
          {/* Category Content Part */}
          <Col className='col-12'>
            <Row className='gap-4 gap-lg-0'>
              <Col className='col-12 col-lg-3 d-grid custom-cat-list align-content-start'>
                <section>
                  <Link to={'/'} className='custom-footer-logo'>
                    <img src={require('../Assets/Images/printfuse blue.png')} alt="" />
                  </Link>
                  <h6 className='fw-semibold pb-2 ps-1'>Join The Next Revolution.</h6>
                  <a
                    href={(window.location.hostname === 'localhost')
                      ? `http://localhost:3001/signup`
                      : `https://app.printfuse.in/signup`
                    }
                    className='custom-footer-info-btn'>Get Started</a>
                </section>
              </Col>
              <Col className='col-12 col-lg-9'>
                <Row className='gy-4 gy-md-0'>
                  <Col className='col-6 col-md-3 d-grid gap-2 custom-cat-list align-content-start list-of-items'>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>About</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Pricing</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Features</Link>
                    <Link to={'/page-integrations'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Integrations</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Career</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Contact</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Contact v2</Link>
                  </Col>
                  <Col className='col-6 col-md-3 d-grid gap-2 custom-cat-list align-content-start list-of-items'>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Shop</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>With sidebar</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Product detail</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Product detail v2</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Cart</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Checkout</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Order confirmation</Link>
                  </Col>
                  <Col className='col-6 col-md-3 d-grid gap-2 custom-cat-list align-content-start list-of-items'>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Request a demo</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Sign in</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Sign in v2</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Sign up</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Sign up v2</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Reset password</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Reset password v2</Link>
                  </Col>
                  <Col className='col-6 col-md-3 d-grid gap-2 custom-cat-list align-content-start list-of-items'>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Blog</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Blog detail</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>FAQ</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>404</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Coming Soon</Link>
                    <Link to={'/'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Terms of service</Link>
                    <Link to={'/privacy-policy'} className='text-capitalize fw-medium fs-16 fs-lg-14'>Privacy policy</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <hr className='mt-4' />

          <Col className='col-12 d-grid d-lg-flex flex-between-align gap-4 gap-lg-0'>
            <div className='d-grid d-md-flex gap-1 gap-md-3 align-items-center'>
              <span className='fs-sm-14 p-color fw-light'>Printfuse® © 2024 All Rights reserved. </span>
              <div className='d-flex gap-3'>
                <Link className='fs-sm-14 fw-semibold link-to-temp'>Privacy notice</Link>
                <Link className='fs-sm-14 fw-semibold link-to-temp'>Legal</Link>
                <Link className='fs-sm-14 fw-semibold link-to-temp'>Cookie setting</Link>
              </div>
            </div>
            <section className='flex-between-align gap-2'>
              <section className='d-flex flex-wrap gap-2 justify-content-start justify-content-lg-end custom-social-link'>
                {
                  webSocialLinks.map((item, index) => {
                    return (
                      <Link to={item.value} key={index} target='_blanck' className='fs-20 flex-between-align half-border-rad'>
                        <span className={`bi bi-${item.label}`}></span>
                      </Link>
                    )
                  })
                }
              </section>
              <span className='custom-footer-line'></span>
              <Dropdown className='custom-footer-lang-btn'>
                <Dropdown.Toggle className='custom-header-lang-btn half-border-rad px-2 fs-14 fs-sm-12 flex-center-align gap-2'>
                  <i className="bi bi-globe-central-south-asia"></i>English
                </Dropdown.Toggle>

                <Dropdown.Menu className='text-end'>
                  <Dropdown.Item>English</Dropdown.Item>
                  <Dropdown.Item>Hindi</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </section>
            {/* <div>
              <span className='fs-12'>Payment methods we accept:</span>
              <div className='payment-logo'>
                <img src={require('../Assets/Images/payment-methods.png')} alt="" />
              </div>
            </div> */}
            {/* <div>
              <span className='fs-12'>We deliver with:</span>
              <div className='payment-logo'>
                <FaUsps className='fs-25 color-dark' />
                <FaDhl className='fs-25 color-dark' />
                <FaFedex className='fs-25 color-dark' />
              </div>
            </div> */}
          </Col>



        </Row>
      </Container>
    </div>
  )
}

export default Footer
