// Path: src/components/PrivacyPolicy.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {

    return (
        <div>
            <Container className='cs-container-xl py-4 py-sm-5'>
                <Row>
                    <Col className='col-md-4 d-none d-md-grid'>
                        <div className=''>
                            <ol className='position-sticky color-temp fs-14 start-0 d-grid gap-3' style={{ top: "65px", listStyleType: "upper-alpha" }}>
                                <li className='pointer'>
                                    <a href="#p-a" className='color-temp text-decoration-none'>Yours and our responsibilities</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-b" className='color-temp text-decoration-none'>When does Printfuse collect your data?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-c" className='color-temp text-decoration-none'>Categories of data collected</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-d" className='color-temp text-decoration-none'>How and why we use your data</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-e" className='color-temp text-decoration-none'>What these legal bases mean</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-f" className='color-temp text-decoration-none'>Your privacy rights and choices</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-g" className='color-temp text-decoration-none'>How secure is the data we collect?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-h" className='color-temp text-decoration-none'>Where do we store the data?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-i" className='color-temp text-decoration-none'>How long do we store your data?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-j" className='color-temp text-decoration-none'>Third parties who process your data</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-k" className='color-temp text-decoration-none'>Cookies</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-l" className='color-temp text-decoration-none'>What if we change this Policy?</a>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-8 d-grid align-content-start'>
                        <div className=' d-grid align-content-start gap-4 gap-sm-5'>
                            <section className='d-grid gap-3 gap-sm-4'>
                                <h1 className='m-0'>Privacy Policy</h1>
                                <address className='m-0 p-text'>
                                    <strong className='fw-semibold'>PRINTFUSE GLOBAL PRIVATE LIMITED</strong> <br />
                                    Ground Floor Plot No 21 <br />
                                    Sec-142 Noida <br />
                                    Uttar Pradesh India 201305
                                </address>
                                <span className='p-text'>Last updated: 1st October 2024</span>
                                <p className='m-0 p-text'>
                                    Thank you for using Printfuse (or as we are legally known, Printfuse).  Before using or accessing our platform, please read this Privacy Policy carefully, it explains how we protect your data and respect your privacy.
                                </p>
                            </section>
                            <section id='p-a' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>A. Yours and our responsibilities</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: This policy applies to all registered Printfuse users and also visitors of our platform. When handling the data of end-customers of Printfuse users, we act as a “data processor” and Printfuse merchants are responsible for ensuring their data is protected and handled in accordance to all applicable laws.
                                </section>
                                <strong className='fw-semibold p-text'>Who does this policy apply to?</strong>
                                <ul className='p-text'>
                                    <li>If you are a Printfuse customer or subscriber, or just visiting our website, this policy applies to you.</li>
                                    <li>For your customers who are just making a purchase of your goods through Printfuse, but are not registered users, we act as the “data processor” for you, the merchant: this means we only process their data to help us provide our service to you, or in accordance with your instructions, or as required by law. You are responsible for making sure that their personal data is treated in accordance with applicable data protection laws. That includes informing them how service providers (like us) collect and use data on your behalf.</li>
                                </ul>
                                <strong className='fw-semibold p-text'>Our responsibilities</strong>
                                <ul className='p-text'>
                                    <li>If you are a registered Printfuse customer or a visitor to our website we act as the  “data controller” of personal data. This means we determine how and why your data are processed.</li>
                                </ul>
                                <strong className='fw-semibold p-text'>Your responsibilities</strong>
                                <ul className='p-text'>
                                    <li>Read this Privacy Policy</li>
                                    <li>If you provide us with personal information about other people (i.e. if you are registering on behalf of someone else, or you are transmitting data from your customers), or if others give us your information, we will only use that information for the specific reason for which it was provided to us. By submitting the information, you confirm that you have the right to authorise us to process it on your behalf in accordance with this Privacy Policy.</li>
                                </ul>
                            </section>
                            <section id='p-b' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>B. When does Printfuse collect your data?</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: Printfuse collects your data in all of our interactions from the moment you visit our website. Some data is collected automatically, and some data is only collected with your consent.
                                </section>
                                <strong className='fw-semibold p-text'>With your consent we collect your data when:</strong>
                                <ul className='p-text'>
                                    <li>You register for an account</li>
                                    <li>You upload content to Printfuse using our Product Designer tool</li>
                                    <li>You integrate Printfuse through API’s (Ours, 3rd party storefronts, website builders, etc….)</li>
                                    <li>You publish products to 3rd party sales channels</li>
                                    <li>We email each other</li>
                                    <li>You contact our support and/or success team (we may also collect call audio, which we will disclose on the call);</li>
                                    <li>When you connect with us on social media</li>
                                    <li>You request a demo of Printfuse</li>
                                    <li>You participate in a user research study</li>
                                    <li>You opt-in to marketing messages</li>
                                    <li>Enter a sweepstakes or contest, or register for a promotion;</li>
                                    <li>Participate in our user referral program;</li>
                                </ul>
                                <strong className='fw-semibold p-text'>Automatically we collect your data when:</strong>
                                <ul className='p-text'>
                                    <li>You browse any part of our website</li>
                                    <li>You use Printfuse to create products</li>
                                    <li>You read our help section</li>
                                    <li>You order products from Printfuse</li>
                                </ul>
                            </section>
                            <section id='p-c' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>C. Categories of data collected</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: Printfuse collects different kinds of data and we process that data for different purposes.
                                </section>
                                <strong className='fw-semibold p-text'>Contact details and basic information</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Your name, address, telephone number, email address…</span>
                                    <span>Financial information</span>
                                    <span>Your Stripe/Paypal account email, UPI, credit/debit card details…</span>
                                </div>

                                <strong className='fw-semibold p-text'>Data from your content and products</strong>
                                <span className='p-text'>The products you have designed, the artwork you have uploaded…</span>

                                <strong className='fw-semibold p-text'>Contractual Data</strong>
                                <span className='p-text'>The sales you have made, your customers’ data, store name, order details…</span>

                                <strong className='fw-semibold p-text'>Data that identifies you</strong>
                                <span className='p-text'>Your IP address, login information, browser type and version, time zone setting, browser plug-in types, geolocation information about where you might be, operating system and version…</span>

                                <strong className='fw-semibold p-text'>Data on how you use Printfuse</strong>
                                <span className='p-text'>Your URL clickstreams (the path you take through our site), products/services viewed, page response times, download errors, how long you stay on our pages, what you do on those pages, how often, and other actions…</span>

                                <strong className='fw-semibold p-text'>What about really sensitive data?</strong>
                                <span className='p-text'>We don’t collect any “sensitive data” about you (like racial or ethnic origin, political opinions, religious/philosophical beliefs, trade union membership, genetic data, biometric data, health data, data about your sexual life or orientation, and offences or alleged offences).</span>

                                <strong className='fw-semibold p-text'>What about children’s data?</strong>
                                <span className='p-text'>Printfuse is a business-to-business service directed to and intended for use only by those who are 18 years of age or over. We do not target Printfuse at children, and we do not knowingly collect any personal data from any person under 16 years of age.</span>
                            </section>
                            <section id='p-d' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>D. How and why we use your data</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: According to Data Protection Law, we can only process your data for specific purposes and only when we have a legal basis to do so. The reasons are outlined below.
                                </section>
                                <strong className='fw-semibold p-text'>Keeping Printfuse running</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Managing your requests (like creating products, integrating sales channels, completing transactions and fulfilling orders), login and authentication, remembering your settings, processing payments, hosting and back-end infrastructure.</span>
                                    <i>Legal basis for processing data: contract, legitimate interests</i>
                                </div>

                                <strong className='fw-semibold p-text'>Improving Printfuse</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat mapping our site, traffic optimization and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this.</span>
                                    <i>Legal basis for processing data: contract, legitimate interests</i>
                                </div>

                                <strong className='fw-semibold p-text'>Customer support</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Notifying you of any changes to our service, solving issues via live chat support, phone or email including any bug fixing.</span>
                                    <i>Legal basis for processing data: contract</i>
                                </div>

                                <strong className='fw-semibold p-text'>Marketing purposes (with your consent)</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Sending you emails and messages about new features, products and services, and content.</span>
                                    <i>Legal basis for processing data: consent</i>
                                </div>
                            </section>
                            <section id='p-e' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>E. What these legal bases mean</h4>
                                <strong className='fw-semibold p-text'>Consent:</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>You have given clear consent for us to process your information for a specific purpose.</span>
                                    <span>You can withdraw your consent at any time by emailing support@printfuse.in.</span>
                                </div>

                                <strong className='fw-semibold p-text'>Contract:</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Processing your data is necessary for a contract you have with us, or because we have asked you to take specific steps before entering into that contract.</span>
                                </div>

                                <strong className='fw-semibold p-text'>Legitimate interests:</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Processing your data is necessary for our legitimate interests or the legitimate interests of a third party, provided those interests are not outweighed by your rights and interests.</span>
                                </div>

                                <strong className='fw-semibold p-text'>These legitimate interests are:</strong>
                                <ul className='p-text'>
                                    <li>gaining insights from your behaviour on our website or in our app</li>
                                    <li>delivering, developing and improving the Printfuse service</li>
                                    <li>enabling us to enhance, customise or modify our services and communication</li>
                                    <li>determining whether marketing campaigns are effective</li>
                                    <li>enhancing data security</li>
                                </ul>
                                <span className='p-text'>In each case, these legitimate interests are only valid if they are not outweighed by your rights and interests.</span>

                            </section>
                            <section id='p-f' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>F. Your privacy rights and choices</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: When it comes to your data and privacy, you have a lot of control for what Printfuse can do. All of your data settings can be managed from your Printfuse account page, or by emailing us at support@printfuse.in. These include your rights to review, correct and delete your data.
                                </section>
                                <span className='p-text'>Your rights</span>
                                <span className='p-text'>You can exercise your rights at any time by sending us an email at support@printfuse.in or by navigating to your Printfuse account page.</span>
                                <strong className='fw-semibold p-text'>You have the right to access information we hold about you</strong>
                                <span className='p-text'>This includes the right to ask us supplementary information about:</span>
                                <ul className='p-text'>
                                    <li>the categories of data we’re processing</li>
                                    <li>the purposes of data processing</li>
                                    <li>the categories of third parties to whom the data may be disclosed</li>
                                    <li>how long the data will be stored (or the criteria used to determine that period)</li>
                                    <li>your other rights regarding our use of your data</li>
                                </ul>
                                <span className='p-text'>We will provide you with the information within one month of your request, unless doing so would adversely affect the rights and freedoms of other (e.g. another person’s confidentiality or intellectual property rights). We’ll tell you if we can’t meet your request for that reason.</span>
                                <strong className='fw-semibold p-text'>You have the right to access the personal information shared with third parties.</strong>
                                <span className='p-text'>Under Indian laws, particularly the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, users have the right to inquire about the disclosure of their personal information to third parties. If you would like to make such a request, please send an email to support@printfuse.in. You have the right to make us correct any inaccurate personal data about you.</span>
                                <span className='p-text'>If after accessing your information, you believe that we have incorrect or inaccurate data, you can request us to change or correct it.</span>
                                <strong className='fw-semibold p-text'>You can object to us using your data for profiling you or making automated decisions about you</strong>
                                <span className='p-text'>We may use your data to determine whether we should let you know information that might be relevant to you (for example, tailoring emails to you based on your behaviour). Otherwise, the only circumstances in which we will do this is to provide the Printfuse service to you.</span>
                                <strong className='fw-semibold p-text'>You have the right to be ‘forgotten’ by us</strong>
                                <span className='p-text'>You can request the deletion of any personal data we hold about you if it is no longer necessary for your use of Printfuse. You also have the right to lodge a complaint regarding our use of your data. We encourage you to contact us first so that we have an opportunity to address your concerns.</span>
                                <span className='p-text'>Please note that India has specific regulations concerning data privacy and the processing of personal information. If you have a complaint about our handling of your data, please reach out to us at support@printfuse.in, and we will do our best to address your concerns.</span>

                                <strong className='fw-semibold p-text'>Your choices</strong>
                                <strong className='fw-semibold p-text'>You can choose not to provide us with personal data</strong>
                                <span className='p-text'>If you choose to do this, you can continue to use the website and browse its pages, but we will not be able to process transactions or fulfill orders without personal data.</span>

                                <strong className='fw-semibold p-text'>You can turn off cookies in your browser by changing its settings</strong>
                                <span className='p-text'>You can block cookies by activating a setting on your browser allowing you to refuse cookies. You can also delete cookies through your browser settings. If you turn off cookies, you can continue to use the website and browse its pages, but Printfuse and certain services will not work effectively.</span>

                                <strong className='fw-semibold p-text'>You can control certain third party collection and use of your information</strong>
                                <span className='p-text'>Third parties may offer ways for you to choose not to have your information collected or used. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website:</span>
                                <span className='p-text'>https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options/</span>
                                <span className='p-text'>To prevent Google Analytics from collecting or using your information, you may install the Google Analytics Opt-Out Browser Add-on; go to Google Analytics Opt-Out Browser Add-on.</span>
                                <span className='p-text'>To opt out of tracking by Mixpanel: https://mixpanel.com/optout/</span>
                                <span className='p-text'>To opt out of tracking by Hotjar: https://www.hotjar.com/legal/compliance/opt-out</span>
                                <span className='p-text'>To manage ads that you see on Google: </span>
                                <span className='p-text'>https://support.google.com/ads/answer/2662922?hl=en</span>
                                <span className='p-text'>To manage ad settings on Facebook:</span>
                                <span className='p-text'>https://www.facebook.com/adpreferences/?entry_product=ad_settings_screen</span>
                                <span className='p-text'></span>

                                <strong className='fw-semibold p-text'>You can ask us not to use your data for marketing</strong>
                                <span className='p-text'>We will inform you (before collecting your data) if we intend to use your data for marketing and if third parties are involved. You can opt out from marketing by emailing us at support@printfuse.in.</span>
                            </section>
                            <section id='p-g' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>G. How secure is the data we collect?</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: We do a lot of things to keep your data safe on our end, but you are also responsible for keeping your account information safe and secure. If you feel that your data has been breached, immediately change your password and get in touch with our support team.
                                </section>
                                <span className='p-text'>We have physical, electronic, and managerial procedures to safeguard and secure the information we collect. For more information on our efforts to ensure your data is held in a secure manner, please contact us at support@printfuse.in.</span>

                                <strong className='fw-semibold p-text'>Our Infrastructure</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>Printfuse operates on servers and cloud components that comply with strict international standards. Access to infrastructure at Printfuse is securely controlled and available from specific locations only. This access is limited to specific employees only and protected by 2-factor authentication.</span>
                                    <span>Data is stored in the Indian region by default on digital ocean servers and is continuously backed up. Our infrastructure’s data centres and cloud services from providers are protected by physical access controls, intrusion and fire detection systems and 24/7 professional security staff.</span>
                                </div>

                                <strong className='fw-semibold p-text'>Document security</strong>
                                <span className='p-text'>All documents and information are version controlled by default.</span>

                                <strong className='fw-semibold p-text'>Monitoring</strong>
                                <span className='p-text'>We have continuous resource and infrastructure access monitoring in operation 24/7, 365 days a year. Any alerts generated by our monitoring system are sent to team members immediately and actioned. Printfuse uses both third parties and in-house scans for vulnerability testing and app protection (WAF).</span>

                                <strong className='fw-semibold p-text'>Training and audits</strong>
                                <span className='p-text'>Regular third party audits are conducted at Printfuse to ensure we use the latest technologies and best practices to protect data. All employees at Printfuse are trained in data security and are bound by strict confidentiality provisions.</span>

                                <strong className='fw-semibold p-text'>And please remember:</strong>
                                <ul className='p-text'>
                                    <li>You provide personal data at your own risk: unfortunately, no data transmission is guaranteed to be 100% secure</li>
                                    <li>You are responsible for the security of your username and password: keep them secret and safe.</li>
                                    <li>If you believe your privacy has been breached, please change your password as soon as possible and contact us immediately on support@printfuse.in.</li>
                                </ul>
                            </section>
                            <section id='p-h' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>H. Where do we store the data?</h4>
                                <span className='p-text'>The personal data we collect is processed at our offices in Noida, UP and in any data processing facilities operated by the third parties identified below.</span>
                                <span className='p-text'>By submitting your personal data, you agree to this transfer, storing or processing by us. If we transfer or store your information outside the country in this way, we will take steps to ensure that your privacy rights continue to be protected as outlined in this Privacy Policy.</span>
                            </section>
                            <section id='p-i' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>I. How long do we store your data?</h4>
                                <span className='p-text'>We will archive and stop actively using any personal identifiable information about you within 6 months from the last time you used Printfuse. We will delete your personal data from our archives no later than 6 years from the last time you used Printfuse or upon your request.</span>
                            </section>
                            <section id='p-j' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>J. Third parties who process your data</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    Summary: To effectively run Printfuse, we need to partner with various third parties around the world. These include our print providers that work with us to offer and fulfil products and services and also tools like Google Apps and zoho desk that allow us to communicate and deliver our service to you. This section outlines who we work, for what purposes and where you can find more information about their privacy policies.
                                </section>
                                <span className='p-text'>To deliver the best possible Printfuse experience we often have to partner with third parties to deliver services that we cannot. These include our print partners that we work with to offer products and fulfil orders, services that support our communications, both internal for our team and external when we communicate with you, tools that keep our web platform and API running, as well as services that allow us to study the use of our platform and learn how best to develop and design new features, messages and offers.</span>
                                <span className='p-text'>When we do this, sometimes it is necessary for us to share your data with them in order to get these services to work well. Your data is shared only when strictly necessary and according to the safeguards and good practices detailed in this Privacy Policy.</span>
                                <span className='p-text'>Here are the details of our main third-party service providers, the data being collected or shared and the reason sharing is necessary.</span>

                                <strong className='fw-semibold p-text'>Infrastructure</strong>
                                <strong className='fw-semibold p-text'>Digital Ocean Privacy policy</strong>

                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>Data that identifies you</li>
                                    <li>contractual data</li>
                                    <li>data on how you use Printfuse</li>
                                </ul>

                                <span className='p-text'>DigitalOcean is an ever-expanding set of cloud services that helps us build, manage, and deploy Printfuse.</span>
                                <strong className='fw-semibold p-text'>Analytics and Marketing</strong>
                                <strong className='fw-semibold p-text'>Google Analytics Privacy policy</strong>

                                <ul className='p-text'>
                                    <li>How you use Printfuse</li>
                                    <li>Data that identifies you</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>Google Analytics is a web analytics service: we use it to track your use of the service, and prepare reports on user activity.</span>

                                <strong className='fw-semibold p-text'>Google Adwords Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Data that identifies you</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>Google Adwords is a marketing tool that allows us to reach potential users and grow the Printfuse audience.</span>

                                <strong className='fw-semibold p-text'>Facebook Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Data that identifies you</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>Facebook business tools  offer a number of services including analytics and advertising that allow us to track your use of the service, prepare reports on user activity and grow Printfuse’s audience.</span>

                                <strong className='fw-semibold p-text'>Unbounce Marketing Solutions Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Data that identifies you</li>
                                    <li>How you use Printfuse</li>
                                    <li>Cookies</li>
                                </ul>
                                <strong className='fw-semibold p-text'>Integrations (by your request)</strong>
                                <strong className='fw-semibold p-text'>Shopify, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>Data that identifies you</li>
                                    <li>Contractual data</li>
                                    <li>Data from your content and products</li>
                                </ul>
                                <span className='p-text'>This integration allows you to sell your products on Shopify and allows us to automate order fulfillment to your end customers.</span>
                                <strong className='fw-semibold p-text'>Bubblestorm Management (Pty) Ltd (WooCommerce.com) Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>Data that identifies you</li>
                                    <li>Contractual data</li>
                                    <li>Data from your content and products</li>
                                </ul>
                                <span className='p-text'>This integration allows you to sell your products on Shopify and allows us to automate order fulfillment to your end customers.</span>
                                <strong className='fw-semibold p-text'>Google, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Data that identifies you</li>
                                    <li>How you use Printfuse</li>
                                    <li>Contact details</li>
                                    <li>Data from your content and products</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>This enables users to connect Google services like Google Drive, where images may be stored.</span>
                                <strong className='fw-semibold p-text'>Communications and Research</strong>
                                <strong className='fw-semibold p-text'>Sendgrid, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>How you use Printfuse</li>
                                    <li>Contractual data</li>
                                </ul>
                                <span className='p-text'>We use this service for sending, storing and tracking emails.</span>
                                <strong className='fw-semibold p-text'>Shiprocket. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contractual data</li>
                                </ul>
                                <span className='p-text'>We use this service for delivering tracking information on your orders.</span>
                                <strong className='fw-semibold p-text'>Typeform S.L. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>How you use Printfuse</li>
                                    <li>Data that identifies you</li>
                                    <li>Contractual data</li>
                                    <li>Data from your content and products</li>
                                </ul>
                                <span className='p-text'>We use this service to learn about user needs, to facilitate order issue submission and to understand and improve Printfuse services by capturing feedback from our users.</span>
                                <strong className='fw-semibold p-text'>Zoho, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>How you use Printfuse</li>
                                    <li>Data from your content and products</li>
                                    <li>Contractual data</li>
                                    <li>Data that identifies you</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>We use this service for customer communications, user interaction,  helpdesk assistance, in app messaging and improving our platform and service.</span>
                                <strong className='fw-semibold p-text'>Google, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>How you use Printfuse</li>
                                    <li>Data from your content and products</li>
                                    <li>Contractual data</li>
                                    <li>Data that identifies you</li>
                                    <li>Cookies</li>
                                </ul>
                                <span className='p-text'>We use Google Suite for business for customer communications, user interaction, User Research, and improving our platform and service.</span>
                                <strong className='fw-semibold p-text'>Payments</strong>
                                <strong className='fw-semibold p-text'>Razorpay, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>Financial information</li>
                                    <li>Cookies</li>
                                    <li>Contractual data</li>
                                </ul>
                                <span className='p-text'>This service processes payments for us.</span>
                                <strong className='fw-semibold p-text'>Stripe, Inc. Privacy policy</strong>
                                <ul className='p-text'>
                                    <li>Contact details</li>
                                    <li>Financial information</li>
                                    <li>Cookies</li>
                                    <li>Contractual data</li>
                                </ul>
                                <span className='p-text'>This service processes payments for us.</span>
                                <strong className='fw-semibold p-text'>Our Print Partners</strong>
                                <span className='p-text'>Printfuse partners with a wide and constantly expanding network of print providers all over the world. Current partners can be seen by signing up to the seller account.</span>
                                <span className='p-text'>These print providers offer various products and print on demand services to our users. Each of our print partners operates as a “data processor” when an order is submitted and they sign a Data Processing Agreement with Printfuse outlining how they handle and protect our users’ data.</span>
                                <strong className='fw-semibold p-text'>This Data Processing Agreement outlines:</strong>
                                <ul className='p-text'>
                                    <li>What personal data is being sent</li>
                                    <li>How that data needs to be secured and kept confidential</li>
                                    <li>The purposes for which that data will be processed</li>
                                    <li>How long that data will be stored</li>
                                    <li>How they respond when users exercise their rights to privacy</li>
                                    <li>How they respond if a data breach occurs</li>
                                </ul>
                            </section>
                            <section id='p-k' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>K. Cookies</h4>
                                <section className='temp-left-border ps-4 fs-20 fs-md-18 fs-sm-16'>
                                    We use cookies. Unless you adjust your browser settings to refuse cookies, we (and these third parties) will issue cookies when you interact with Printfuse. These may be ‘session’ cookies, meaning they delete themselves when you leave Printfuse, or ‘persistent’ cookies which do not delete themselves and help us recognise you when you return so we can provide a tailored service.
                                </section>
                                <strong className='fw-semibold p-text'>How can I block cookies?</strong>
                                <div className='p-text d-grid gap-3'>
                                    <span>You can block cookies by activating a setting on your browser allowing you to refuse the setting of cookies. You can also delete cookies through your browser settings. If you use your browser settings to disable, reject, or block cookies (including essential cookies), certain parts of our website will not function fully. In some cases, our website may not be accessible at all. Please note that where third parties use cookies we have no control over how those third parties use those cookies.</span>
                                </div>
                                <strong className='fw-semibold p-text'>Which specific cookies do we use?</strong>
                                <strong className='fw-semibold p-text'>Google, Inc.</strong>
                                <span className='p-text'>Google Analytics uses cookies allows us to see information on the activities of visitors to our website and users of our service, including page views, source and time spent on Printfuse. The information is depersonalized and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy.</span>
                                <span className='p-text'>To opt out of being tracked by Google Analytics across all websites click here.</span>

                                <strong className='fw-semibold p-text'>Zoho, Inc.</strong>
                                <span className='p-text'>Zoho uses cookies to recognise particular people who have interacted with Zoho through Printfuse previously. This may be visitors to our website, Printfuse users or counterparties.</span>
                                <span className='p-text'>You may refuse the transmission of your Information by opting out (contact them direct for more information), however please note that you will delete the opt out cookie when you delete your cookies in your browser settings</span>

                                <strong className='fw-semibold p-text'>Facebook, Inc.</strong>
                                <span className='p-text'>Facebook uses cookies if visitors have a Facebook account, use the Facebook Products, including their website and apps, or visit other websites and apps that use the Facebook Products (including the Like button or other Facebook Technologies). Cookies enable Facebook to offer the Facebook Products to visitors and to understand the information they receive about visitors, including information about their use of other websites and apps, whether or not they are registered or logged in.</span>
                                <span className='p-text'>To opt out of being tracked by Facebook by following the instructions here.</span>
                            </section>
                            <section id='p-l' className='d-grid gap-2 gap-sm-3'>
                                <h4 className='m-0 fw-semibold'>L. What if we change this Policy?</h4>
                                <div className='p-text d-grid gap-3'>
                                    <span>We reserve the right to make changes to this Privacy Policy at any time to reflect changes in the law, our data collection and use practices, the features of our Services, or advances in technology. Please check this page periodically for changes. If we make any material changes to this Privacy Policy, we will notify you via the email address provided for Registered Users and we will also post a notice on our home page and post the updated Privacy Policy here.</span>
                                    <span>The date the Privacy Policy was last revised is identified at the top of the page. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will mean you consent to and accept those changes. If you do not consent to such changes, you should stop using our Services.</span>
                                    <span>Contact us at any time at support@printfuse.in, if you have more questions about this privacy policy, your rights, or our privacy practices.</span>
                                </div>
                            </section>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PrivacyPolicy;
