import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

function ProfitCalculator(props = {}) {

    const [activeSlide, setActiveSlide] = useState(0);
    const [activeSlideData, setActiveSlideData] = useState(props?.products[0]);
    const [basePrice, setBasePrice] = useState(props?.products[0]?.startPrice);
    const [rangeValue, setRangeValue] = useState([50]);
    const [disPer, setDisPer] = useState(0.07);
    const [disPrice, setDisPrice] = useState(parseFloat(basePrice * disPer).toFixed(2))
    const [profitData, setProfitData] = useState({
        amount: 0,
        count: 0
    });

    const handleRangeChange = (e) => {
        if (!e.target.value) {
            setRangeValue(0);
        }
        else {
            setRangeValue(parseInt(e.target.value));
        }
    };

    useEffect(() => {
        console.log("activeSlide::", activeSlide);
        console.log("activeSlideData::", activeSlideData);
        setProfitData((prevData) => ({
            ...prevData,
            amount: (activeSlideData?.startPrice + activeSlideData?.startPrice * .3).toFixed(0),
            count: 500
        }))
    }, [activeSlideData])

    const newSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "110px",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
            setActiveSlideData(props?.products[next])
        },
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: "80px",
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: "125px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerPadding: "80px",
                }
            },
            {
                breakpoint: 400,
                settings: {
                    centerPadding: "60px",
                }
            },
        ]
    };

    useEffect(() => {
        setBasePrice(props?.products[activeSlide]?.startPrice);
        setDisPrice(parseFloat(basePrice * disPer).toFixed(2));
        if (rangeValue > 500) {
            setDisPer(0.28);
        } else if (rangeValue > 400) {
            setDisPer(0.25);
        } else if (rangeValue > 300) {
            setDisPer(0.21);
        } else if (rangeValue > 200) {
            setDisPer(0.18);
        } else if (rangeValue > 100) {
            setDisPer(0.14);
        } else if (rangeValue > 50) {
            setDisPer(0.11);
        } else if (rangeValue > 25) {
            setDisPer(0.07);
        } else {
            setDisPer(0);
        }
    }, [activeSlide, basePrice, disPer, rangeValue, props?.products])

    function formatRupee(amount) {
        const amountStr = amount.toString();
        const lastThreeDigits = amountStr.slice(-3);
        const otherDigits = amountStr.slice(0, -3);

        // Add commas after every 2 digits for the remaining part
        const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

        // Combine both parts
        return otherDigits ? formattedOtherDigits + ',' + lastThreeDigits : lastThreeDigits;
    }

    return (
        <div className='custom-home-bulk'>
            <Container className='custom-home-bulk-container p-2 py-5'>
                {/* <h3 className='fw-semibold m-0 text-center pb-5 pt-4'>Potential earnings with your <br /> print on demand business</h3> */}
                <div className='flex-center-align py-4 pb-5'>
                    <h2 className='m-0 fw-semibold text-center fs-sm-20 d-grid d-sm-grid justify-content-sm-center gap-2'>
                        Potential earnings with your <br />
                        <span className='flex-center-align gap-2'>
                            <span className='custom-diff-title '>Print On Demand</span>
                            business
                        </span>
                    </h2>
                </div>
                <div className='flex-center-align'>
                    <Row className='custom-home-bulk-container-row p-2 p-sm-4 py-4 py-sm-5 gy-4'>
                        <Col className='col-12 col-lg-6 px-0 px-sm-2'>
                            <div className="slider-container">
                                <Slider {...newSettings}>
                                    {
                                        props.products.map((item, index) => {
                                            return (
                                                <div key={index} className='text-center custom-sld-items d-grid gap-3'>
                                                    <div className='d-grid gap-2'>
                                                        <h5 className='m-0 fw-semibold'>{item?.shortName}</h5>
                                                        <Link className='fs-12'>{item?.shortName} | Printfuse P127845</Link>
                                                    </div>
                                                    <div className='product-border'>
                                                        <img src={item?.thumbnail} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-6 d-grid align-content-between custom-home-bulk-container-col'>
                            <div className='d-grid'>
                                <h5 className='fs-sm-16 fw-semibold color-temp m-0'>{activeSlideData?.name}</h5>
                                <span className='p-color fs-14 fs-sm-12'>Printfuse P127845</span>
                            </div>

                            <hr className='my-3' />
                            <div className='flex-between-align'>
                                <div className='d-grid'>
                                    <h6 className='fs-sm-16 fw-semibold m-0 p-color'>You pay for product</h6>
                                    <span className='p-color fs-12 fs-sm-10'>Product costs exclude taxes</span>
                                </div>
                                <div className='d-grid fw-semibold align-content-start'>{formatRupee(activeSlideData?.startPrice)} INR</div>
                            </div>
                            <hr className='my-3' />
                            <div className='d-grid gap-3'>
                                <div className='flex-between-align custom-remove-number'>
                                    <span className='fw-semibold p-color'>Your selling price</span>
                                    <input
                                        type="number"
                                        className='py-1 px-2 px-sm-3 py-sm-2 og-border half-border-rad text-center fs-18 fs-sm-16'
                                        style={{ maxWidth: "125px" }}
                                        value={profitData?.amount}
                                        placeholder={`Sugg. ₹${(activeSlideData?.startPrice + activeSlideData?.startPrice * .3).toFixed(0)}`}
                                        onChange={(e) => setProfitData((prevData) => ({
                                            ...prevData,
                                            amount: e?.target?.value
                                        }))}
                                    />
                                </div>
                                <div className='flex-between-align custom-remove-number'>
                                    <div className='d-grid'>
                                        <span className='fs-sm-16 fw-semibold m-0 p-color'>Your monthly sales</span>
                                        <span className='p-color fs-12 fs-sm-10'>Product costs exclude taxes</span>
                                    </div>
                                    <input
                                        type="number"
                                        className='py-1 px-2 px-sm-3 py-sm-2 og-border half-border-rad text-center fs-18 fs-sm-16'
                                        value={profitData?.count}
                                        style={{ maxWidth: "125px" }} placeholder='Sugg. 500'
                                        onChange={(e) => setProfitData((prevData) => ({
                                            ...prevData,
                                            count: e?.target?.value
                                        }))}
                                    />
                                </div>
                            </div>
                            <hr className='my-3' />
                            <div className='flex-between-align gap-2 align-items-start'>
                                <div className='d-grid gap-1 w-100'>
                                    <span className='fs-sm-16 fw-semibold m-0 p-color'>Your profits per month</span>
                                    <span className='p-color fs-12 fs-sm-10'>This number does not include other costs you may have outside Printfuse.</span>
                                </div>
                                <div className='d-grid w-100 text-end align-content-start'>
                                    <h5 className='fs-sm-18 fw-semibold m-0 p-color text-dark'>{formatRupee((profitData?.amount - activeSlideData?.startPrice) * profitData?.count)} INR</h5>
                                    <span className='p-color fs-12 fs-sm-10'> <span className='color-temp fw-semibold'>{((profitData?.amount - activeSlideData?.startPrice) / 100).toFixed(0)}%</span> profit margin</span>
                                </div>
                            </div>
                            <div className='pt-3 d-grid'>
                            <Link className='temp-btn fw-semibold text-center'>Browse products</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ProfitCalculator
