import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { frontEnd_API, header, SERVER_URL } from '../../Config/Config';
import { GoDotFill } from 'react-icons/go';

function CategoryList() {

    const [data, setData] = useState();
    const [selectedData, setSelectedData] = useState();

    const getCategoryList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API?.getcategory, header)
            if (data?.data?.length > 0) {
                const tempData = data?.data?.slice(0, 4)
                let finalData = tempData.map((item) => {
                    const newData = { ...item, children: item?.children.slice(0, 4) }
                    return newData
                })

                setData(finalData);
                console.log("finalData::", finalData);
                setSelectedData(finalData[0])
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getCategoryList();
    }, [])

    return (
        <div className='custom-category-list'>
            <Container className="cs-container-xl py-4 py-sm-5 d-grid gap-5">
                <div className='d-grid gap-4'>
                    <div className='flex-center-align' data-aos="fade-up">
                        <div className=' text-center d-grid gap-3 gap-sm-4'>
                            <div className='flex-between-align'>
                                <div className='custom-home-title-light'>
                                    <GoDotFill className='fs-12' />
                                    <span className='fs-12'>Product Category</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-center-align'>
                        <h2 className='m-0 fw-semibold text-center d-grid d-sm-flex justify-content-sm-center gap-2'>
                            Custom
                            <span className='flex-center-align gap-2'>
                                <span className='custom-diff-title '>Print on Demand</span>
                            </span>
                            Products
                        </h2>
                    </div>
                    <div className='flex-center-align gap-2 pt-4 flex-wrap'>
                        {
                            (data?.length > 0) &&
                            data.map((item, index) => (
                                <button key={index} className={`text-capitalize ${(selectedData?.label == item?.label) ? "temp-btn" : "dis-select-btn"}`} onClick={() => setSelectedData(item)}>{item?.label}</button>
                            ))
                        }
                    </div>
                </div>
                {
                    (selectedData) &&
                    (selectedData?.children?.length == 4) &&
                    <Row className='gy-4'>
                        <Col className='col-lg-6 d-none d-lg-grid'>
                            <div className='same-border-rad fourth-img-first'>
                                <img src={SERVER_URL + selectedData?.children[0]?.thumbnail} alt="" />
                                <div className='custom-back-bg flex-center-align fs-45 fs-sm-20 fw-semibold text-uppercase'>
                                    {selectedData?.children[0]?.label}
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-6 d-grid'>
                            <Row className='gy-4'>
                                <Col className='col-12 col-sm-6 col-lg-6 d-grid d-lg-none'>
                                    <div className='same-border-rad fourth-img-second'>
                                        <img src={SERVER_URL + selectedData?.children[0]?.thumbnail} alt="" />
                                        <div className='text-center custom-back-bg flex-center-align fs-35 fs-sm-20 fw-semibold text-uppercase'>
                                            {selectedData?.children[0]?.label}
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-sm-6 col-lg-6 d-grid'>
                                    <div className='same-border-rad fourth-img-second'>
                                        <img src={SERVER_URL + selectedData?.children[1]?.thumbnail} alt="" />
                                        <div className='text-center custom-back-bg flex-center-align fs-35 fs-sm-20 fw-semibold text-uppercase'>
                                            {selectedData?.children[1]?.label}
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-sm-6 col-lg-6 d-grid'>
                                    <div className='same-border-rad fourth-img-second'>
                                        <img src={SERVER_URL + selectedData?.children[2]?.thumbnail} alt="" />
                                        <div className='text-center custom-back-bg flex-center-align fs-35 fs-sm-20 fw-semibold text-uppercase'>
                                            {selectedData?.children[2]?.label}
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-sm-6 col-lg-12 d-grid'>
                                    <div className='same-border-rad fourth-img-second'>
                                        <img src={SERVER_URL + selectedData?.children[3]?.thumbnail} alt="" />
                                        <div className='text-center custom-back-bg flex-center-align fs-35 fs-sm-20 fw-semibold text-uppercase'>
                                            {selectedData?.children[3]?.label}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    (selectedData) &&
                    (selectedData?.children?.length <= 3) &&
                    <Row className='gy-4'>
                        {
                            selectedData?.children?.map((item, index) => (
                                <Col key={index} className={`${(selectedData?.children?.length == 1)
                                    ? "col-12" :
                                    (selectedData?.children?.length == 2)
                                        ? "col-12 col-sm-6" :
                                        (selectedData?.children?.length == 3)
                                            ? "col-12 col-sm-6 col-lg-4" :
                                            ""}`}
                                >
                                    <div className={`w-100 same-border-rad ${(selectedData?.children?.length == 1)
                                        ? "aspect-2-1" :
                                        (selectedData?.children?.length == 2)
                                            ? "aspect-2-1" :
                                            (selectedData?.children?.length == 3)
                                                ? "aspect-2-1" :
                                                ""}`}
                                    >
                                        <img src={SERVER_URL + item?.thumbnail} className='w-100 h-100 full-border-rad og-border' style={{ objectFit: "cover" }} alt="" />
                                        <div className='custom-back-bg flex-center-align fs-45 fs-sm-20 fw-semibold text-uppercase'>
                                            {item?.label}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                }
            </Container>
        </div>
    )
}

export default CategoryList