import React from 'react'
// import CommonBanner from '../../Components/CommonBanner'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Banner() {
    return (
        <div className='py-0 py-lg-5 custom-home-banner'>
            <Container fluid className='p-3'>
                <Container fluid className='custom-home-banner-container p-3 py-4 p-sm-4 p-lg-5 border-rad-md-half'>
                    <Container className='cs-container-xl' style={{ overflow: 'hidden' }}>
                        <Row className='gy-4 gy-lg-0'>
                            <Col className='col-12 col-lg-6 d-grid align-content-between gap-3 text-center text-lg-start' data-aos="fade-up">
                                <h2 className='display-4 m-0 fw-semibold'>Start your ecommerce business risk-free</h2>
                                <div className='d-grid gap-1'>
                                    <p className='m-0 fs-14 fs-sm-12'>sell online with print-on-demand</p>
                                    <p className='m-0 fs-14 fs-sm-12'>Sign up and create your snap store for free. </p>
                                </div>
                                <div className='d-flex justify-content-center justify-content-lg-start'>
                                    <Link className='custom-btn-style d-flex fw-medium gap-2'>
                                        Get started
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-6 d-grid' data-aos="fade-left">
                                <div className='custom-img-holder'>
                                    <img src={require('../../Assets/Images/template/home-06-builder-tools.png')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}

export default Banner
