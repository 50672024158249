import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom'

function PriceList() {

    const listData = [
        [
            "Real-time tracking and notifications",
            "Real-time analytics",
            "Drag and drop templates",
            "Project Management",
            "24/7 email and chat support"
        ],
        [
            "CRM and Zapier integrations",
            "Content reporting",
            "Unlimited team workspaces",
            "Approval workflows",
            "Salesforce integration*"
        ],
        [
            "Unlimited files uploads",
            "Real-time tracking and notifications",
            "User performance",
            "SSO support and custom user roles",
            "Bulk send & Forms*"
        ]
    ]

    return (
        <div className='custom-price-list pt-4'>
            <Container fluid className='p-0 p-lg-3'>
                <Container fluid className='custom-img-container p-0'>
                    <Container className='custom-img-section'>
                        <div className='custom-img-section-contain-color'></div>
                        <div className='custom-img-section-contain'></div>
                        <div className='custom-img-section-contain-blend-center'></div>
                        <div className='custom-img-section-contain-blend-left'></div>
                        <div className='custom-img-section-contain-blend-right'></div>
                        <div className='custom-slider-content pt-5 gap-4 gap-sm-4 d-grid'>
                            {/* Content Part */}
                            <div className='custom-slider-content-list flex-center-align align-items-start' data-aos="fade-up">
                                <div className='custom-slider-content-list-first lg-max-w-750px max-w-600px gap-4 d-grid'>
                                    <div className='flex-center-align'>
                                        <div className='custom-home-title'>
                                            <GoDotFill className='fs-12' />
                                            <span className='fs-12'>Pricing</span>
                                        </div>
                                    </div>
                                    <div className='text-center custom-slider-heading d-grid gap-4 px-4 px-sm-0'>
                                        <h1 className='h1 m-0 d-flex justify-content-center gap-2 flex-wrap'>
                                            Simple
                                            <span>scalable</span>
                                            pricing.
                                        </h1>
                                        <p className='m-0 d-none d-md-block p-color'>No extra charges. No hidden fees.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Image Part */}
                            <Container className='custom-slider-content-list-second cs-container-xl p-0'>
                                <Row className='justify-content-center gy-4 gy-xl-0'>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="0">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-2 gap-sm-3'>
                                                <b className='fw-bold fs-20'>Essentials</b>
                                                <p className='m-0 fs-sm-12'>For creating impressive tools that generate results.</p>
                                                <h2 className='m-0 display-3 fw-semibold'>
                                                    ₹0.00
                                                    {/* <span className='h2 fw-semibold'>/ mo</span> */}
                                                </h2>
                                                <p className='m-0 fs-14 fs-sm-12 p-color'>Seat per month, 2 seats max</p>
                                                <div className='d-grid gap-2'>
                                                    <Link className='fw-medium fs-18 fs-sm-16'>Start a free trial</Link>
                                                    <span className='fs-14 fs-sm-12 p-color text-center'>No credit card required</span>
                                                </div>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-1 gap-2'>
                                                <div className='fw-semibold pb-2 fs-sm-14'>Key features:</div>
                                                {
                                                    listData[0].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-20 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0 fs-14 fs-sm-12'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="0">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-2 gap-sm-3'>
                                                <b className='fw-bold fs-20'>Enterprise</b>
                                                <p className='m-0 fs-sm-12'>For seamless integrations and sending tools in bulk.</p>
                                                <h2 className='m-0 display-3 fw-semibold'>
                                                    ₹499.99
                                                    {/* <span className='h2 fw-semibold'>/ mo</span> */}
                                                </h2>
                                                <p className='m-0 fs-14 fs-sm-12 p-color'>Seat cost per month</p>
                                                <div className='d-grid gap-2'>
                                                    <Link className='fw-medium fs-18 fs-sm-16'>Start a free trial</Link>
                                                    <span className='fs-14 fs-sm-12 p-color text-center'>No credit card required</span>
                                                </div>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-1 gap-2'>
                                                <div className='fw-semibold pb-2 fs-sm-14'>Everything in Essentials, plus:</div>
                                                {
                                                    listData[1].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-20 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0 fs-14 fs-sm-12'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='custom-lable fs-14 fw-medium'>Popular</div>
                                            <div className='custom-icon fs-14 fw-medium'>
                                                <img src={require("../../Assets/Images/Untitled design (199).png")} alt="" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="0">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-2 gap-sm-3'>
                                                <b className='fw-bold fs-20'>Business</b>
                                                <p className='m-0 fs-sm-12'>For large companies with complex Tool workflows.</p>
                                                <h2 className='m-0 display-3 fw-semibold'>
                                                    Let’s talk
                                                    {/* <span className='h2 fw-semibold'>/ mo</span> */}
                                                </h2>
                                                <p className='m-0 fs-14 fs-sm-12 p-color'>Per‑seat or per‑tool pricing</p>
                                                <div className='d-grid gap-2'>
                                                    <Link className='fw-medium fs-18 fs-sm-16 bg-dark'>Contact sales</Link>
                                                    <span className='fs-14 fs-sm-12 p-color text-center'>Respond within 24 hrs max</span>
                                                </div>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-1 gap-2'>
                                                <div className='fw-semibold pb-2 fs-sm-14'>Everything in Business, plus:</div>
                                                {
                                                    listData[2].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-20 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0 fs-14 fs-sm-12'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <span className='text-center p-color mt-4 mb-0 mb-sm-5 mt-sm-5'>Prices exclude any applicable taxes.</span>
                                </Row>
                            </Container>
                        </div>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}

export default PriceList