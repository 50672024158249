import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaLink } from 'react-icons/fa'

function IntegrateBanner() {
    return (
        <div className='py-5'>
            <div className='custom-integrate-banner py-5'>
                <Container className=' py-5'>
                    <Row>
                        <Col className='col-12 col-md-9 d-grid'>
                            <div className='d-grid gap-4 color-light-main'>
                                <div className='flex-center-align aspect-1-1' style={{ height: "80px", borderRadius: "50%", backgroundColor: "#25614e" }}>
                                    <FaLink className='fs-35' />
                                </div>
                                <h2 className='m-0 h1 fw-semibold'>Integrate with Leading E-commerce Platforms</h2>
                                <p className='m-0 '>Connect your store to Printfuse effortlessly with the best print-on-demand integrations. Set up your store on the world's most trusted e-commerce platforms and marketplaces, then link it to Printfuse in minutes to start selling!</p>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-3 d-grid'>
                            <div className='position-relative d-grid aspect-md-2-1'>
                                <img src={require('../../Assets/Images/woo-C1jOQYFa.png')} alt="" className='integrate-img position-absolute start-0 bottom-0' style={{ height: "80px" }} />
                                <img src={require('../../Assets/Images/shop-BcjMsh4N.png')} alt="" className='integrate-img position-absolute end-0 top-0' style={{ height: "80px" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default IntegrateBanner