import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GiSewingNeedle } from "react-icons/gi";

function Info() {

    const infoList = [
        {
            title: "Create Custom Products",
            description: "Create designs using our product customizer or use your designs on products.",
            image: require("../../Assets/Images/banner1.png")
        },
        {
            title: "Sell Online",
            description: "Create a free snap store or set up your online store and integrate with Printfuse. List your product with your margins.",
            image: require("../../Assets/Images/Untitled design (50).png")
        },
        {
            title: "Scale Your Brand",
            description: "Promote and scale your brand while we fulfill your orders.",
            image: require("../../Assets/Images/Untitled design (51).png")
        },
    ]
    const [activeTab, setActiveTab] = useState(infoList[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveTab(prevTab => {
                // Find the index of the current active tab
                const currentIndex = infoList.indexOf(prevTab);
                // Get the next index, or loop back to 0 if we're at the end
                const nextIndex = (currentIndex + 1) % infoList.length;
                // Return the next item in the list
                return infoList[nextIndex];
            });
        }, 2500); // 2000ms = 2 seconds

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='py-5 px-2 px-sm-0'>
            <Container className='cs-container-xl custom-info half-border-rad pt-4 pt-md-5'>
                <Row>
                    <Col className='col-12 col-lg-6 d-grid pb-md-5'>
                        <section className='ps-1 ps-lg-4 custom-info-cnt d-grid align-content-between'>
                            <section>
                                <h1 className='color-dark-main fw-bold fs-md-20 pb-4'>How it works</h1>

                                <div className='d-grid gap-2'>
                                    {
                                        infoList.map((item, index) => (
                                            <section key={index} className={`d-flex pointer pt-3 ${(activeTab?.title == item?.title) ? "custom-info-active" : "custom-info-default"}`} style={{ transition: ".3s" }}
                                                onClick={() => setActiveTab(item)}
                                            >
                                                <div className='px-4 d-flex flex-column align-items-center'>
                                                    <div className='custom-info-circle'>
                                                        <div className={`m-0 ${(activeTab?.title == item?.title) ? "custom-info-circle-active" : ""}`}>
                                                            <h5 className='px-3 py-2 fw-bold'>{index + 1}</h5>
                                                        </div>
                                                    </div>
                                                    {
                                                        ((index + 1) != infoList?.length) &&
                                                        <div className='custom-info-line' />
                                                    }
                                                </div>
                                                <div>
                                                    <h5 className='color-dark-main fw-bold'>{item?.title}</h5>
                                                    <p>{item?.description}</p>
                                                </div>
                                            </section>
                                        ))
                                    }
                                </div>
                            </section>
                            <div className='flex-center-align pt-3 d-sm-flex gap-4 custom-slider-btn'>
                                <Link to={'/'} className='custom-slider-start flex-center-align gap-2 temp-btn'><GiSewingNeedle />Start Designing</Link>
                            </div>
                        </section>
                    </Col>
                    <Col className='col-12 col-lg-6 pe-0 custom-info-image ps-5 ps-lg-1 pt-5 pt-lg-0'>
                        <img src={activeTab?.image} className='w-100' alt="" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Info;
