import React, { useEffect, useState } from 'react'
import { IoIosArrowUp } from 'react-icons/io'

function ScrollToTop() {

    const [showBtn, setShowBtn] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Hide header when scrolling down, show when scrolling up
            if (currentScrollY > lastScrollY && currentScrollY > 50) {
                setShowBtn(false);  // Scrolling down, hide header
            } else {
                setShowBtn(true);   // Scrolling up, show header
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const handleScroll = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <button
                className='custom-top-btn flex-center-align'
                style={showBtn ? { transform: `translateY(calc(100% + 20px))` } : { transform: `translateY(0%)` }}
                onClick={handleScroll}
            >
                <IoIosArrowUp className='fs-25' />
            </button>
        </div>
    )
}

export default ScrollToTop