import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CommonBrands from '../../Components/CommonBrands'

function HomeTitleTab() {
    return (
        <div>
            <Container fluid className='custom-home-title-tab'>
                <Container className='cs-container-xl py-4'>
                    <Row className='gy-5'>
                        <Col className='col-12 col-lg-5 d-grid align-content-center'>
                            <div className='d-grid gap-4'>
                                <h1 className='m-0 display-3 text-center fw-semibold text-lg-start'>Selling Custom Products Made Simple</h1>
                                <p className='fs-20 fs-lg-18 fs-md-16 fs-sm-14 m-0 text-center text-lg-start'>Unlock your productivity potential with our intuitive and powerful to-do app. Manage tasks, set priorities, collaborate with your team, and track progress effortlessly.</p>
                                <div className='d-grid gap-2'>
                                    <div className='d-grid d-sm-flex gap-2 title-btns'>
                                        <button className='green-btn temp-btn w-100 fw-semibold'>Try it our for Free</button>
                                        <button className='green-btn temp-btn w-100 fw-semibold bg-dark'>How it works?</button>
                                    </div>
                                    <span className='p-color fs-14 fs-sm-12'>We care about your data in our <Link className="color-temp fw-medium">privacy policy.</Link></span>
                                </div>
                                <div className='d-grid m-auto m-lg-0 d-sm-flex mt-2 justify-content-sm-center justify-content-lg-start gap-2 gap-sm-3 home-title-review'>
                                    <div className='d-flex m-auto m-sm-0 img-list'>
                                        <img src={require('../../Assets/Images/testi-avatar-1.jpg')} alt="" style={{ transform: "translate(0%)" }} />
                                        <img src={require('../../Assets/Images/testi-avatar-2.jpg')} alt="" style={{ transform: "translate(75%)" }} />
                                        <img src={require('../../Assets/Images/testi-avatar-3.jpg')} alt="" style={{ transform: "translate(150%)" }} />
                                        <img src={require('../../Assets/Images/testi-avatar-4.jpg')} alt="" style={{ transform: "translate(225%)" }} />
                                    </div>
                                    <div className='content-list d-grid gap-0'>
                                        <div className='d-flex gap-2 justify-content-center justify-content-sm-start align-items-center'>
                                            <span>
                                                <i className="bi bi-star-fill text-warning" />
                                                <i className="bi bi-star-fill text-warning" />
                                                <i className="bi bi-star-fill text-warning" />
                                                <i className="bi bi-star-fill text-warning" />
                                                <i className="bi bi-star-fill text-warning" />
                                            </span>
                                            <span className='fw-semibold fs-14'>5.0</span>
                                        </div>
                                        <span className='fw-medium fs-14'>Based on 200+ reviews</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-lg-1 d-none d-lg-grid p-0' />
                        <Col className='col-12 col-lg-6 d-grid  align-content-center'>
                            <div className='title-img-holder'>
                                <img src={require('../../Assets/Images/hero-05.jpg')} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='py-3'>
                    <CommonBrands
                        sliderTitle={"14,000+ customers large and small rely on us for trust transformation"}
                        containerType={"cs-container-xl"}
                        titleCSS={"fs-25 fs-sm-14"}
                        sideEffect={true}
                    />
                </div>
            </Container>
        </div>
    )
}

export default HomeTitleTab